import {
  WordpressWpBrand,
  WordpressAcfBrandAcfBestDeals,
  WordpressWpCarConnection,
  WordpressWpCarEdge,
  WordpressWpCar,
} from '@/generated-models';
import { ImageDataLike } from 'gatsby-plugin-image';

export interface YoastMetaType {
  yoast_wpseo_title: string | undefined;
  yoast_wpseo_metadesc: string | undefined;
}

declare const graphql: (query: TemplateStringsArray) => void;

declare global {
  interface Window {
    reviewsBadgeRibbon: (id: string, options: { store: string; size: string }) => void;
  }
}

export type GatsbyBgImage = ImageDataLike | null;

export {};
declare global {
  interface Window {
    gtag: any;
  }
}

export interface BreadcrumbsType {
  pathname: string | undefined;
  crumbLabel: string | null | undefined;
}

export enum FuelType {
  All = 'All',
  PetrolElectric = 'Petrol/Electric',
  Diesel = 'Diesel',
  DieselElectric = 'Diesel/PlugIn Electric',
  Petrol = 'Petrol',
}

export enum Titles {
  Mr = 'Mr',
  Mrs = 'Mrs',
  Miss = 'Miss',
  Ms = 'Ms',
}

export enum LeaseType {
  Personal = 'personal',
  Business = 'business',
}

export enum TradingStyles {
  LimitedCompany = 'Limited Company',
  LLP = 'LLP',
  PLC = 'PLC',
  Partnership = 'Partnership',
  SoleTrader = 'Sole Trader',
}

export type LeaseYears = 4 | 3 | 2;

export interface SelectOption {
  label: string;
  value: string;
}

export interface SelectedAddons {
  value: string;
  label: string;
  priceExcVat: number;
  priceIncVat: number;
}

export interface GroupOption {
  label: string;
  options: {
    isPOA: boolean;
    label: string;
    parent: string;
    priceExcVat: number;
    priceIncVat: number;
    value: string;
  }[];
}

export interface IAppState {
  notifications: {
    isActive: boolean;
    message: string;
  };
  specialOffers: {
    agreementTerms: number;
    annualMileage: number;
    bodyStyles: SelectOption[];
    brands: SelectOption[];
    fuelType: string;
    initialRental: number;
    initialVariants: Array<WordpressWpCarEdge>;
    isFiltered: boolean;
    isLoading: boolean;
    isLoadingPosts: boolean;
    leaseType: LeaseType;
    page: number;
    postsToShow: number;
    postsPerPage: number;
    price: Array<number>;
    sortValue: string;
    transmission: string;
    variants: Array<WordpressWpCarEdge>;
  };
  brand: {
    activeStep: number;
    addonsTotal: number;
    agreementTerms: number;
    annualMileage: number;
    bestDeals: WordpressAcfBrandAcfBestDeals;
    brand: WordpressWpBrand;
    depositPrice: number;
    formData: {
      deliveryDate: Date;
      maintenance: 'Yes' | 'No';
      businessName: string;
      email: string;
      forename: string;
      phoneNumber: string;
      surname: string;
      title: ContactTitles;
      tradingStyle: TradingStyles;
      yearsTrading: number;
    };
    fuelType: string;
    fuelTypes: [FuelType];
    groupOptions: [GroupOption];
    initialRental: number;
    initialVariants: WordpressWpCarConnection;
    isErrorAddons: boolean;
    isLoading: boolean;
    isError: boolean;
    isLoadingAddons: boolean;
    isLoadingVariant: boolean;
    isSubmit: boolean;
    isSubmitError: boolean;
    isSubmitting: boolean;
    leaseType: LeaseType;
    monthlyPrice: number;
    selectedAddons: SelectOption[];
    selectedVariant: WordpressWpCar;
    trim: string;
    variants: WordpressWpCarConnection;
  };
}

export type SelectedAddon = {
  value: string;
  label: string;
  priceExcVat: number;
  priceIncVat: number;
};

export type AddonOption = {
  isPOA: boolean;
  label: string;
  parent: string;
  priceExcVat: number;
  priceIncVat: number;
  value: string;
};

export type AddonGroupOption = {
  label: string;
  options: Array<Option>;
};

declare module '*.svg';
declare module '*.jpg';
declare module '*.png';
declare module '*.webp';

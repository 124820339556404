import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './src/css/global.css';
import { createRoot } from 'react-dom/client';
import type { GatsbyBrowser } from 'gatsby';
import wrapWithProvider from '@/wrap-with-provider';

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  setTimeout(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', { page_path: pagePath });
    }
  }, 100);
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = createRoot(container);
    root.render(element);
  };
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = wrapWithProvider;

import {
  OFFERS_FILTERED,
  OFFERS_LOADING_POSTS,
  OFFERS_LOADING,
  OFFERS_RESET,
  OFFERS_UPDATE_AGREEMENT_TERMS,
  OFFERS_UPDATE_ANNUAL_MILEAGE,
  OFFERS_UPDATE_BODY_STYLES,
  OFFERS_UPDATE_BRANDS,
  OFFERS_UPDATE_FUEL,
  OFFERS_UPDATE_INITIAL_RENTAL,
  OFFERS_UPDATE_INITIAL_VARIANTS,
  OFFERS_UPDATE_PAGE,
  OFFERS_UPDATE_PREFERENCES,
  OFFERS_UPDATE_PRICE,
  OFFERS_UPDATE_SORT,
  OFFERS_UPDATE_TRANSMISSION,
  OFFERS_UPDATE_VARIANTS,
  OFFERS_UPDATE_POSTS_PER_PAGE,
  OFFERS_UPDATE_LEASE_TYPE,
} from '@/actions/specialOffers';
import {
  searchResultsPerPage,
  searchResultsPerPageOptions,
  searchPreSelectedBrands,
} from '@/constants';

const savedPreferences =
  typeof window !== 'undefined' &&
  localStorage.getItem('content') &&
  JSON.parse((typeof window !== 'undefined' && localStorage.getItem('content')) || '');

const initialState = {
  agreementTerms: (savedPreferences && savedPreferences.agreementTerms) || 48,
  annualMileage: (savedPreferences && savedPreferences.annualMileage) || 8000,
  bodyStyles: [],
  brands: [...searchPreSelectedBrands],
  fuelType: 'All',
  initialRental: (savedPreferences && savedPreferences.initialRental) || 12,
  initialVariants: [],
  isFiltered: false,
  isLoading: true,
  isLoadingPosts: false,
  leaseType: (savedPreferences && savedPreferences.leaseType) || 'business',
  page: 1,
  postsPerPage: searchResultsPerPageOptions[0],
  postsToShow: searchResultsPerPage,
  price: [150, 1500],
  sortValue: 'Price: Lowest',
  transmission: 'All',
  variants: [],
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case OFFERS_UPDATE_LEASE_TYPE: {
      return {
        ...state,
        leaseType: action.payload,
      };
    }

    case OFFERS_UPDATE_POSTS_PER_PAGE: {
      return {
        ...state,
        postsPerPage: action.payload,
      };
    }
    case OFFERS_UPDATE_SORT: {
      return {
        ...state,
        sortValue: action.payload,
      };
    }
    case OFFERS_FILTERED: {
      return {
        ...state,
        isFiltered: action.payload,
      };
    }

    case OFFERS_LOADING_POSTS: {
      return {
        ...state,
        isLoadingPosts: action.payload,
      };
    }

    case OFFERS_UPDATE_PAGE: {
      return {
        ...state,
        page: action.page,
        postsToShow: action.postsToShow,
      };
    }

    case OFFERS_UPDATE_PRICE: {
      return {
        ...state,
        price: action.payload,
      };
    }

    case OFFERS_UPDATE_TRANSMISSION: {
      return {
        ...state,
        transmission: action.payload,
      };
    }

    case OFFERS_UPDATE_BODY_STYLES: {
      return {
        ...state,
        bodyStyles: action.payload,
      };
    }

    case OFFERS_UPDATE_BRANDS: {
      return {
        ...state,
        brands: action.payload,
      };
    }

    case OFFERS_UPDATE_INITIAL_VARIANTS: {
      return {
        ...state,
        initialVariants: action.payload,
      };
    }

    case OFFERS_UPDATE_VARIANTS: {
      return {
        ...state,
        variants: action.payload,
      };
    }

    case OFFERS_UPDATE_FUEL: {
      return {
        ...state,
        fuelType: action.payload,
      };
    }

    case OFFERS_UPDATE_PREFERENCES: {
      return {
        ...state,
        initialRental: action.initialRental,
        annualMileage: action.annualMileage,
        agreementTerms: action.agreementTerms,
        leaseType: action.leaseType,
      };
    }

    case OFFERS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case OFFERS_UPDATE_INITIAL_RENTAL:
      return {
        ...state,
        initialRental: parseInt(action.payload),
      };

    case OFFERS_UPDATE_ANNUAL_MILEAGE:
      return {
        ...state,
        annualMileage: parseInt(action.payload),
      };

    case OFFERS_UPDATE_AGREEMENT_TERMS:
      return {
        ...state,
        agreementTerms: parseInt(action.payload),
      };

    case OFFERS_RESET: {
      return {
        ...initialState,
        isLoading: false,
        initialVariants: state.initialVariants,
        variants: state.initialVariants,
      };
    }

    default:
      return state;
  }
};

export default reducers;

export const NOTIFICATIONS_ACTIVE = 'dashboard/NOTIFICATIONS_ACTIVE';

export function setNotification(isActive: boolean, message: string) {
  return {
    isActive,
    message,
    type: NOTIFICATIONS_ACTIVE,
  };
}

/**
 * Displays a toast notification message
 * @param isActive
 * @param message
 */
export const handleDisplayNotification = (isActive: boolean, message: string) => (dispatch) => {
  dispatch(setNotification(isActive, message));
};
